import React, { Fragment } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Head from "../components/head"

const EventsPage = () => {
  const today = new Date()

  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { fields: [frontmatter___eventdate], order: DESC }
      ) {
        group(field: fields___year) {
          fieldValue
          edges {
            node {
              id
              frontmatter {
                title
                eventdateRaw: eventdate
                eventdate(formatString: "DD MMM")
              }
              fields {
                slug
              }
            }
          }
        }
      }
    }
  `)

  var groupedByYear = data.allMarkdownRemark.group

  // Present events in reverse order by year
  groupedByYear.sort(function(a, b) {
    return parseInt(b.fieldValue, 10) - parseInt(a.fieldValue, 10)
  })

  // Flatten the array of events, then extract just the events in the future
  const upcomingEvents = groupedByYear
    .flatMap(group => {
      return group.edges
    })
    .filter(event => new Date(event.node.frontmatter.eventdateRaw) > today)

  var upcomingHtml
  // <p>There are no events scheduled right now but please check back soon for updates!</p>

  if (upcomingEvents.length === 0) {
    upcomingHtml = (
      <div className="content">
        <p>
          Please note that we are currently constrained by the pandemic, along
          with the rest of the country and hope to resume our activities in the
          not too distant future.
        </p>
      </div>
    )
  } else {
    upcomingHtml = (
      <table className="table is-narrow">
        <colgroup>
          <col style={{ width: "6em" }} />
          <col />
        </colgroup>
        <tbody>
          {upcomingEvents.map(event => {
            return (
              <tr>
                <td>{event.node.frontmatter.eventdate}</td>
                <td>
                  <Link to={`/events/${event.node.fields.slug}`}>
                    {event.node.frontmatter.title}
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  return (
    <Layout title="Events">
      <Head title="Events" description="Upcoming and past events." />

      <h2 className="title">Upcoming events</h2>
      {upcomingHtml}
      <div className="content">
        <p>
          To receive notice of upcoming events please sign up to{" "}
          <a href="https://groups.google.com/forum/#!forum/irish-skeptics/join">
            our mailing list
          </a>
          .
        </p>
      </div>

      <h2 className="title">Past events</h2>
      {groupedByYear.map(group => {
        return (
          <Fragment key={group.fieldValue}>
            <h3 className="title is-4 is-year">{group.fieldValue}</h3>
            <table className="table is-narrow">
              <colgroup>
                <col style={{ width: "6em" }} />
                <col />
              </colgroup>
              <tbody>
                {group.edges.map(edge => {
                  if (new Date(edge.node.frontmatter.eventdateRaw) < today) {
                    return (
                      <tr key={edge.node.id}>
                        <td>{edge.node.frontmatter.eventdate}</td>
                        <td>
                          <Link to={`/events/${edge.node.fields.slug}`}>
                            {edge.node.frontmatter.title}
                          </Link>
                        </td>
                      </tr>
                    )
                  } else {
                    return null
                  }
                })}
              </tbody>
            </table>
          </Fragment>
        )
      })}
    </Layout>
  )
}

export default EventsPage
